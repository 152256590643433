import LayoutA4 from "../../components/layout-a4"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A4EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA4 location={this.props.location} >
        <EnrollInfoDetail  color={color}/>
      </LayoutA4>
    )
  }
}